import { answerDomain } from '../actions/surveyActions';
import AnswerButton from '../components/AnswerButton';
import Answers from '../components/Answers';
import Illustration from '../components/Illustration';
import SurveyPageTemplate from '../components/SurveyPageTemplate';
import { Domain, AnswerLevel } from '../model/models';
import { State } from '../redux-state';
import { breakpoints, colors, fonts } from '../styles';
import useLoadingAndError from '../utils/useLoadingAndError';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const CongratulationText = styled.div`
    ${fonts.content};
    color: ${colors.libertyBlue};
    margin-bottom: 50px;
`;

const Continue = styled.div`
    ${fonts.content};
    color: ${colors.libertyBlue};
    display: flex;
    align-items: center;
    ${breakpoints.sm} {
        margin-left: 20px;
    }
    ${breakpoints.md} {
        margin-left: 20px;
    }
    ${breakpoints.lg} {
        margin-left: 20px;
    }
    ${breakpoints.largeDesktop} {
        margin-left: 20px;
    }
    ${breakpoints.largerDesktop} {
        margin-left: 20px;
    }
`;

const SizedAnswerButton = styled(AnswerButton)`
    ${breakpoints.onlyMobile} {
        margin: auto;
        margin-bottom: 10px;
    }
    width: 250px;
    height: 50px;
    margin-bottom: 10px;
`;

const DomainsList = styled.ul`
    ${fonts.content};
    text-align: left;
`;

const getIsDoneContent = (
    congratulationText: string,
    nextText: string,
    enterShortcut: string,
    survey_id: any,
    uid: any,
    levels_state: any,
    emailAuth: any,
) => {
    return (
        <React.Fragment>
            <CongratulationText
                dangerouslySetInnerHTML={{
                    __html: congratulationText,
                }}
            />
            <SizedAnswerButton
                backgroundType="content"
                tooltipTextColor={colors.blue}
                color={colors.blue}
                shortcut={enterShortcut}
                to={`/${survey_id}/${uid}/survey-welcome`}
                levels_state={levels_state}
                emailAuth={emailAuth}
            >
                <Continue>{nextText}</Continue>
            </SizedAnswerButton>
        </React.Fragment>
    );
};

const getNotEnoughContent = (
    notEnoughDomainsText: string,
    restartText: string,
    nextText: string,
    enterShortcut: string,
    restartFunction: () => void,
    domains: Domain[],
    survey_id: any,
    uid: any,
    levels_state: any,
    emailAuth: any,
) => {
    return (
        <React.Fragment>
            <CongratulationText
                dangerouslySetInnerHTML={{
                    __html: notEnoughDomainsText.split('#')[0],
                }}
            />
            <DomainsList>
                {domains.map((domain, i) => {
                    if (
                        !domain.preEvaluationLevel ||
                        domain.preEvaluationLevel < 1
                    )
                        return <li key={i}>{domain.title}</li>;
                })}
            </DomainsList>
            <CongratulationText
                dangerouslySetInnerHTML={{
                    __html: notEnoughDomainsText.split('#')[1],
                }}
            />

            <SizedAnswerButton
                backgroundType="content"
                tooltipTextColor={colors.blue}
                color={colors.blue}
                shortcut={enterShortcut}
                to={`/${survey_id}/${uid}/survey-welcome`}
                levels_state={levels_state}
                emailAuth={emailAuth}
            >
                <Continue>{nextText}</Continue>
            </SizedAnswerButton>
            <SizedAnswerButton
                levels_state={levels_state}
                backgroundType="content"
                tooltipTextColor={colors.blue}
                color={colors.blue}
                shortcut={'r'}
                onClick={restartFunction}
            >
                <Continue>{restartText}</Continue>
            </SizedAnswerButton>
        </React.Fragment>
    );
};

const didUserAnsweredNextQuestion = (
    nonMandatoryDomains: Domain[],
    currentDomainIndex: number,
) => {
    return (
        nonMandatoryDomains[currentDomainIndex] &&
        nonMandatoryDomains[currentDomainIndex].preEvaluationLevel != null
    );
};
const PresurveyPage: React.FC = (): // {
//     history
// },
React.ReactElement => {
    const dispatch = useDispatch();
    const [isDone, setIsDone] = useState(false);
    const [minimalScore, setMinimalScore] = useState(0);
    const [currentDomain, setCurrentDomain] = useState({} as any as Domain);
    const [currentDomainIndex, setCurrentDomainIndex] = useState(0);

    const domainDefaultProficiency: number | undefined = useSelector(
        (state: State): number | undefined =>
            state.surveyState.domainDefaultProficiency || undefined,
    );

    const answering = useSelector(
        (state: State): boolean => state.surveyState.answerLoading,
    );

    /**
     * Bind last timestamp to re-render on answer if required (edge case)
     */

    const lastTimeStamp: number = useSelector(
        (state: State): number => state.surveyState.userInfos.lastAnsweredTime,
    );

    /**
     * Bind all nonMandatoryDomains to this container
     */
    const nonMandatoryDomains: Domain[] = useSelector(
        (state: State): Domain[] => state.surveyState.domains,
    ).filter((domain: Domain) => !domain.mandatory);

    /**
     * Bind all nonMandatoryDomains to this container
     */
    const domains: Domain[] = useSelector(
        (state: State): Domain[] => state.surveyState.domains,
    );

    /**
     * Bind the domainCursor to this container
     */
    const domainCursor: number = useSelector(
        (state: State): number => state.surveyState.domainCursor,
    );

    const completed: boolean = useSelector(
        (state: State): boolean => state.surveyState.presurveyCompleted,
    );

    /**
     * Bind app loading and error state
     */
    const { loading } = useLoadingAndError();

    /**
     * Effect functions
     */

    /**
     * Set internal cursor in the component to be able to go to next or previous question
     * without answering it
     */

    const effectFunctionSetDomainIndexFromCursorChange = () => {
        setCurrentDomainIndex(domainCursor);
    };

    /**
     * @Effect
     * Check is the presurvey is done
     */

    const effectFunctionSetIsDone = () => {
        let acc = 0;
        for (let i = 0; i < domains.length; i++) {
            const level = domains[i].preEvaluationLevel || 0;
            if (level > 0) acc++;
        }
        setMinimalScore(acc);

        if (completed && currentDomainIndex >= nonMandatoryDomains.length)
            setIsDone(true);
        else setIsDone(false);
    };

    /**
     * @Effect
     * Get current domain and set it
     */

    const effectFunctionSetDomain = () => {
        // history.push(`/pre-survey/${currentDomainIndex}`);
        // if (nonMandatoryDomains[currentDomainIndex]) {
        setCurrentDomain(nonMandatoryDomains[currentDomainIndex]);

        // );
        // }
    };

    /**
     * Place the cursor on the next domain
     */
    const nextDomain = () => {
        if (
            didUserAnsweredNextQuestion(nonMandatoryDomains, currentDomainIndex)
        )
            setCurrentDomainIndex(currentDomainIndex + 1);
    };

    /**
     * On answer click, request
     * @param domain
     * @param answerLevel
     */

    const onAnswer = (
        domain: Domain,
        answerLevel: AnswerLevel,
        survey_id: any,
        uid: any,
    ) => {
        if (!answering) {
            dispatch(answerDomain(domain, answerLevel, survey_id, uid));
        }
    };

    const getQuestionExplanation = (currentDomain: Domain) => {
        let result = '';
        if (Object.hasOwn(currentDomain, 'subdomains')) {
            currentDomain.subdomains.forEach((subdomain) => {
                result += subdomain.title + '<br />';
            });
            return result;
        }
        return '';
    };

    /**
     * Place the cursor on the previous domain
     */
    const previousDomain = () => {
        if (currentDomainIndex > 0) {
            setCurrentDomainIndex(currentDomainIndex - 1);
        }
    };

    const getNextFunction = (
        currentIndex: number,
        totalQuestion: number,
        isDone: boolean,
        nextFunction: () => void,
    ) => {
        return domainDefaultProficiency 
            ? isDone
                ? undefined
                : currentDomain &&
                  currentDomain.preEvaluationLevel != null &&
                  currentIndex !== totalQuestion
                ? nextFunction
                : () =>
                      onAnswer(
                          currentDomain,
                          domainDefaultProficiency as AnswerLevel,
                          survey_id,
                          uid,
                      )
            : (currentIndex === totalQuestion &&
                  !isDone &&
                  currentDomain.preEvaluationLevel == null) ||
              isDone ||
              !didUserAnsweredNextQuestion(
                  nonMandatoryDomains,
                  currentDomainIndex,
              )
            ? undefined
            : currentDomain.preEvaluationLevel != null &&
              currentIndex === totalQuestion
            ? () =>
                  onAnswer(
                      currentDomain,
                      currentDomain.preEvaluationLevel != null
                          ? currentDomain.preEvaluationLevel
                          : 0,
                      survey_id,
                      uid,
                  )
            : nextFunction;
    };

    const restartFunction = () => {
        setCurrentDomainIndex(0);
    };

    useEffect(effectFunctionSetDomainIndexFromCursorChange, [
        domainCursor,
        lastTimeStamp,
    ]);
    useEffect(effectFunctionSetDomain, [
        currentDomainIndex,
        nonMandatoryDomains,
    ]);
    useEffect(effectFunctionSetIsDone, [
        completed,
        currentDomainIndex,
        nonMandatoryDomains,
    ]);

    const { t } = useTranslation(['PresurveyPage']);
    const { survey_id, uid } = useParams();

    let emailAuth;
    let levels_state;
    const navigate = useNavigate();
    const location = useLocation();
    const stateVar = location.state;
    console.log(stateVar);
    if (!stateVar || !stateVar.emailAuth) {
        navigate(`/${survey_id}/${uid}`, { replace: true });
        return <div></div>;
    } else {
        emailAuth = stateVar.emailAuth;
        levels_state = stateVar.levels_state;
    }
    return (
        <SurveyPageTemplate
            title={isDone ? t('titleDone') : t('title')}
            logoImage="/talentbuilderlogo.png"
            doneText={isDone ? t('doneText') || '' : undefined}
            questionPhrase={t('questionPhrase')}
            questionDomain={currentDomain && currentDomain.title}
            questionDomainExplanation={
                currentDomain && getQuestionExplanation(currentDomain)
            }
            targetSample={currentDomain && currentDomain.targetSample}
            progress={
                currentDomainIndex + 1 > nonMandatoryDomains.length
                    ? nonMandatoryDomains.length
                    : currentDomainIndex + 1
            }
            progressTotal={nonMandatoryDomains.length}
            nextClick={getNextFunction(
                currentDomainIndex,
                nonMandatoryDomains.length - 1,
                isDone,
                () => nextDomain(),
            )}
            backClick={
                currentDomainIndex > 0 ? () => previousDomain() : undefined
            }
            illustrationSideContent={
                <Illustration
                    currentDomainIndex={currentDomainIndex}
                    domains={domains}
                    survey_id={survey_id}
                    uid={uid}
                    levels_state={levels_state}
                    domainDefaultProficiency={domainDefaultProficiency}
                />
            }
            showHelpByDefault={false}
            shouldShowTooltip={true}
        >
            {isDone ? (
                minimalScore == domains.length ? (
                    getIsDoneContent(
                        t('congratulationText'),
                        t('nextText'),
                        t('enter'),
                        survey_id,
                        uid,
                        levels_state,
                        emailAuth,
                    )
                ) : (
                    getNotEnoughContent(
                        t('notEnoughDomains'),
                        t('restartText'),
                        t('nextText'),
                        t('enter'),
                        restartFunction,
                        domains,
                        survey_id,
                        uid,
                        levels_state,
                        emailAuth,
                    )
                )
            ) : (
                <Answers
                    loading={loading}
                    survey_id={survey_id}
                    levels_state={levels_state}
                    showLevels={domainDefaultProficiency ? false : true}
                    previousAnswer={
                        currentDomain && currentDomain.preEvaluationLevel
                    }
                    onAnswer={(answerLevel: AnswerLevel) =>
                        onAnswer(currentDomain, answerLevel, survey_id, uid)
                    }
                />
            )}
        </SurveyPageTemplate>
    );
};

export default PresurveyPage;
